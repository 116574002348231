 /* eslint-disable */
 /* THIS FILE IS CALLED BY WEBPACK */
 const glob = require("glob");
 const path = require("path");

 
 module.exports = function mergeJSON(absoluteGlobPattern) {
     const dataFiles = glob.sync(absoluteGlobPattern);
     const resultingData = {
         "LANGS": {},
         "TIMESTAMP": new Date().getTime()
     };
     dataFiles.forEach(filepath => {
         const id = path.basename(filepath, ".json");
         const lang = id.split("_")[1];
         if (resultingData.LANGS[lang]) {
             throw new Error('Duplicate lang '+lang+'. It has already been added to result.');
         }
         resultingData.LANGS[lang] = require(filepath);
     });
     resultingData.STRINGS=resultingData.LANGS["el"];    //TODO: set from environment variable
     return resultingData;
 };  